<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">用户管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="loginName" placeholder="请输入登录名" class="iw" ></el-input>
                <el-select v-model="type" placeholder="选择用户类型"  filterable  clearable class="iw">
                        <el-option label="系统管理员" value="1" ></el-option>
                        <el-option label="仓库管理员" value="2" ></el-option>
                        <el-option label="收件员" value="3" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TYPE" label="用户类型" >
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE==1">系统管理员</span>
                            <span v-else-if="scope.row.TYPE==2">仓库管理员</span>
                            <span v-else-if="scope.row.TYPE==3">收件员</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="姓名"></el-table-column>
                    <el-table-column prop="PHONE" label="手机号"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="类型"  prop="Type">
                    <el-select v-model="postInfo.Type" placeholder="请选择用户类型"  filterable clearable  :disabled="doType=='2'" style="width:100%;">
                        <el-option label="系统管理员" value="1" ></el-option>
                        <el-option label="仓库管理员" value="2" ></el-option>
                        <el-option label="收件员" value="3" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="postInfo.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="loginname">
                    <el-input v-model="postInfo.loginname" autocomplete="off"  :disabled="doType=='2'"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="doType=='1'">
                    <el-input v-model="postInfo.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var Type = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择用户类型"));
      } else {
        callback();
      }
    };
    var displayname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    var loginname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入登录名"));
      } else {
        callback();
      }
    };
    var password = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var phone = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };

    return {
        canDo:true,
        showPagination:false,
        loginName:"",
        name:"",
        type:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        
        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            Type: [{ validator: Type, trigger: "blur" }],
            loginname: [{ validator: loginname, trigger: "blur" }],
            password: [{ validator: password, trigger: "blur" }],
            displayname: [{ validator: displayname, trigger: "blur" }],
            phone: [{ validator: phone, trigger: "blur" }],
        },
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.loginName=getQueryData.loginName
        this.name=getQueryData.disPlayName
        this.type=getQueryData.UserType
    }
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            loginName:this.loginName,
            disPlayName:this.name,
            UserType:this.type
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("SysUser/GetSysUserList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
                this.hideLoading()
                this.postList=[]
                this.Total=0
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            Type:"",
            loginname:"",
            password:"",
            displayname:"",
            phone:"",
        }
        this.doTypeStr="添加用户"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("SysUser/InsertSysUser", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    editPost(item){
        this.postInfo={
            SnowID:item.SNOWID,
            Type:item.TYPE.toString(),
            loginname:item.LOGINNAME,
            displayname:item.DISPLAYNAME,
            phone:item.PHONE
        }
        this.doTypeStr="修改用户"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("SysUser/UpdateSysUser", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if(response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                }else{
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            if(item.SNOWID == this.$store.state.snowid){
                this.$message.error("不能删除当前登录账号") 
            }else{
                this.$confirm('确定删除用户：'+item.DISPLAYNAME+'？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("SysUser/DeleteSysUser", {SnowID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>