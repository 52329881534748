import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


import AdminUserManage from '../views/User/AdminUserManage.vue'
import StoreTypeManage from '../views/Store/StoreTypeManage.vue'
import StoreManage from '../views/Store/StoreManage.vue'
import StoreArea from '../views/Store/StoreArea.vue'
import RackManage from '../views/Store/RackManage.vue'
import VideoManage from '../views/Store/VideoManage.vue'

import GoodsManage from '../views/Goods/GoodsManage.vue'
import InOutManage from '../views/Logs/InOutManage.vue'

import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/AdminUserManage',
        name: 'AdminUserManage',
        components: {AdminUserManage}
      },
      {
        path: '/StoreTypeManage',
        name: 'StoreTypeManage',
        components: {StoreTypeManage}
      },
      {
        path: '/StoreManage',
        name: 'StoreManage',
        components: {StoreManage}
      },
      {
        path: '/StoreArea',
        name: 'StoreArea',
        components: {StoreArea}
      },
      {
        path: '/RackManage',
        name: 'RackManage',
        components: {RackManage}
      },
      {
        path: '/VideoManage',
        name: 'VideoManage',
        components: {VideoManage}
      },
      {
        path: '/GoodsManage',
        name: 'GoodsManage',
        components: {GoodsManage}
      },
      {
        path: '/InOutManage',
        name: 'InOutManage',
        components: {InOutManage}
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var initRouter= store.state.initRouter
  if(initRouter == ""){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next(true);
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
